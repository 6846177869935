* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  padding: 0;
  margin: 0;
}

#root {
  --primary: #33C8B4;
  --secondary: #FF8F00;
  --background: #031625;
  --backgroundAlt: #162633;
  --body: #FFFFFF;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: max-content;
  min-height: calc(100vh - 72px);
  background-color: #031625;
}

h1, h2, h3, p {
  color: var(--body);
}

p {
  font-size: 0.8rem;
  text-align: left;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

hr {
  width: 100%;
  color: var(--body);
}

.popupBackground {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #00000066;
  backdrop-filter: blur(5px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 102;
  opacity: 0;
  transition: opacity 200ms;
}

.reduceMotion * {
  transition: none !important;
}
