.workNav {
    width: 100%;
    height: 320px;
    border-radius: 10px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow: hidden;
    box-shadow: 1px 1px 8px 1px #00000080;
    transition: height 250ms;
}

.category:first-child, .category:first-child .categoryOverlay {
    border-radius: 8px 0 0 8px;
}

.category:last-child, .category:last-child .categoryOverlay {
    border-radius: 0 8px 8px 0;
}

.category {
    position: relative;
    width: 64px;
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    flex-grow: 0;
    background-size: 100vw;
    background-position: center;
    overflow: hidden;
    transition: width 250ms, flex-grow 250ms;
}

.category:not(.activeCategory) {
    cursor: pointer;
}

.activeCategory {
    width: auto;
    flex-direction: row;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: center;
}

.categoryOverlay {
    position: absolute;
    top: -5%;
    left: 0;
    height: 110%;
    width: 100%;
    backdrop-filter: blur(4px) brightness(60%);
    z-index: 1;
    transition: background 150ms;
}

.category:not(.category:first-child) .categoryOverlay {
    box-shadow: inset 2px 0 8px 1px #00000080;
}

.categoryIcon {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 16px;
    left: 16px;
    stroke: var(--body);
    z-index: 2;
}

.categoryName {
    font-size: 1.7rem;
    text-align: center;
    transform: rotate(-90deg);
    color: white;
    z-index: 2;
    user-select: none;
    transition: transform 150ms;
}

.category:not(.activeCategory) .categoryName {
    position: absolute;
    left: -76px;
    width: 240px;
    height: 64px;
}

.activeCategory .categoryName {
    transform: rotate(0);
}

@media only screen and (max-height: 800px) {
    .workNav {
        height: 64px;
    }

    .category, .activeCategory {
        width: auto;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px;
    }

    .activeCategory .categoryOverlay {
        backdrop-filter: blur(4px) brightness(100%);
    }

    .categoryOverlay:not(.activeCategory) {
        box-shadow: none !important;
    }

    .activeCategory {
        box-shadow: 0 0 12px 2px black !important;
        z-index: 2;
        border-bottom: 3px solid var(--body);
    }

    .categoryIcon {
        position: static;
    }

    .categoryName {
        display: none;
    }
}