.detailsPopup {
    position: absolute;
    width: calc(100vw - 32px);
    max-width: 1800px;
    height: calc(100vh - 32px);
    top: 16px;
    left: max(calc((100vw - 1800px) / 2), 16px);
    padding: 16px 16px 24px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    background: var(--backgroundAlt);
    box-shadow: 1px 1px 12px 2px #000000B3;
    z-index: 2;
}

.detailsHead {
    position: relative;
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.popupCloseDetails {
    position: absolute;
    right: 0;
    top: 0;
    width: 24px;
    height: 24px;
    flex-grow: 0;
    stroke: var(--body);
    cursor: pointer;
    transition: stroke 200ms;
}

.popupCloseDetails:hover {
    stroke: var(--primary);
}

.contentImages, .contentCodePen {
    width: 100%;
    height: auto;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}

.imageGallery {
    width: 100%;
    height: 64px;
    padding: 8px 0;
    background: var(--background);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    border-radius: 8px;
}

.imagePrev, .imageNext {
    width: 48px;
    height: 48px;
    padding: 8px;
    cursor: pointer;
    background: transparent;
}

.imageNavIcon {
    width: 100%;
    height: 100%;
    stroke: var(--body);
    transition: stroke 200ms;
}

.imageNavIcon:hover {
    stroke: var(--primary);
}

.imageList {
    width: max-content;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: center;
    align-items: center;
}

.listImage {
    height: 48px;
    width: auto;
    aspect-ratio: 16 / 9;
    border-radius: 4px;
    border: 2px solid transparent;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    image-rendering: smooth;
    background-repeat: no-repeat;
    transition: border-color 200ms;
}

.currentImage {
    border-color: var(--secondary);
}

.bigImageContainer {
    width: 100%;
    height: auto;
    max-height: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 8px;
    border-radius: 8px;
    overflow: hidden;
    background: var(--background);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bigImage {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}

.codePenEmbed {
    width: 100%;
    height: auto;
    flex-grow: 1;
}

.codePenEmbed iframe {
    height: 100% !important;
}

.contentDescription {
    width: 100%;
    height: 10%;
    min-height: 96px;
    overflow-y: auto;
}

.contentDescription p {
    width: 100%;
    height: max-content;
    font-size: 1rem;
}

@media only screen and (max-height: 800px) {
    .contentDescription p {
        font-size: 0.8rem;
    }
}